import Modal from './modules/modal.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();

	if (document.querySelector('.cta-box .close')) {
		document
			.querySelector('.cta-box .close')
			.addEventListener('click', (e) =>
				e.currentTarget.parentNode.classList.add('hide')
			);

		window.addEventListener('scroll', debounce(checkPosition));
	}

	function checkPosition() {
		let windowY = window.scrollY;
		if (windowY > 500) {
			if (
				document.querySelector('.site-main > .cta-box') &&
				!document
					.querySelector('.site-main > .cta-box')
					.classList.contains('hide')
			) {
				document.querySelector('.site-main > .cta-box').classList.add('active');
			}
		}
	}

	function debounce(func, wait = 10, immediate = true) {
		let timeout;
		return function () {
			let context = this,
				args = arguments;
			let later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			let callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}

	const elTriggers = document.querySelectorAll('.tabs-nav li');

	[...elTriggers].forEach((trigger) => {
		trigger.addEventListener('click', switchTab);
	});

	function switchTab(e) {
		e.preventDefault();
		let elTarget = e.currentTarget.dataset.tab;

		removeActive('.tabs-nav li[data-active]');
		removeActive('.tab[data-active]');

		e.currentTarget.dataset.active = true;
		document.querySelector(`.tab[data-tab=${elTarget}]`).dataset.active = true;
	}

	const removeActive = function (selector) {
		[...document.querySelectorAll(selector)].forEach(
			(el) => delete el.dataset.active
		);
	};

	const cases = document.querySelectorAll('[data-cursor]');

	cases.forEach((el) => {
		el.addEventListener('mouseenter', (e) => {
			e.currentTarget.querySelector('.cursor').classList.add('visible');
		});

		el.addEventListener('mouseleave', (e) => {
			e.currentTarget.querySelector('.cursor').classList.remove('visible');
		});

		el.addEventListener('mousemove', (e) => {
			let bounds = e.currentTarget.getBoundingClientRect();
			let left = e.clientX - bounds.left;
			let top = e.clientY - bounds.top;
			e.currentTarget
				.querySelector('.cursor')
				.setAttribute('style', 'top: ' + top + 'px; left: ' + left + 'px;');

			el.addEventListener('click', (e) => {
				e.currentTarget.querySelector('.cursor').classList.add('click');

				setTimeout(() => {
					e.currentTarget.querySelector('.cursor').classList.remove('click');
				}, 500);
			});
		});
	});

	// Assumes that you're using jQuery and Bootstrap
	// The `ajaxurl` variable, should be declared in
	// header.php like so:
	// ajaxurl = '<?php echo admin_url('admin-ajax.php'); ?>';

	var $modal = $('#modal');
	var $modal_target = $('[data-modal="target"]');
	$('[data-section="showcase"]').on('click', '.zoom', function (e) {
		e.preventDefault();
		var id = $(this).closest('.card').data('id');

		$.ajax({
			url: xpl.ajaxurl,
			data: {
				action: 'fetch_modal_content',
				id: id
			},
			success: function (data) {
				$modal_target.html(data);
				$modal.modal('show');
			}
		});
	});
});

// CUSTOM SCRIPTS KOMEN HIER ONDER --------------------------------------------------------------------------//
//-----------------------------------------------------------------------------------------------------------//

// accordion
jQuery(document).ready(function ($) {
	$('.accordion-1 > .item .titel').click(function() {
		if($(this).parent().hasClass('toon')){
			$(this).parent().removeClass('toon');
		} else {
			$('.accordion-1 > .item.toon').removeClass('toon');
			$(this).parent().toggleClass('toon');
		}
		return false;
	});
	$('.accordion-1 > .item').click(function() {
		if($(this).hasClass('toon')){
			$(this).removeClass('toon');
		}
		// $(this).removeClass('toon');
		return false;
	});
});
// $('.accordion-1 > .item:first-of-type').addClass('toon');